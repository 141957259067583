@import url(https://cdn.rawgit.com/mfd/09b70eb47474836f25a21660282ce0fd/raw/e06a670afcb2b861ed2ac4a1ef752d062ef6b46b/Gilroy.css);
:root {
    --crossmint-main-green: #00ff85;
    --crossmint-main-blue: #00e0ff;
    --crossmint-main-dark-bg: #1e1e1e;
}

/* Mint Button */

.client-sdk-button-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.875rem 0.875rem;
    font-weight: 900;
    transition: opacity ease-in-out 0.25s;
    border-radius: 0.5rem;
    font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    outline: none;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    justify-content: center;
}

.client-sdk-button-trigger p {
    margin: 0;
}

.client-sdk-button-trigger:hover:enabled {
    opacity: 0.6;
    cursor: pointer;
}

.client-sdk-button-trigger-dark {
    background: #1e1e1e;
    background: var(--crossmint-main-dark-bg);
}

.client-sdk-button-trigger-light {
    background: white;
}

.client-sdk-button-trigger-light p {
    color: black;
}

.client-sdk-button-trigger-dark p {
    color: white;
}

.client-sdk-button-trigger-colored {
    background-image: linear-gradient(to bottom right, #00ff85, #00e0ff);
    background-image: linear-gradient(to bottom right, var(--crossmint-main-green), var(--crossmint-main-blue));
    color: white;
}

.client-sdk-button-icon {
    width: 21px;
    height: 21px;
    margin-right: 0.875rem;
}

/* Modal */
.client-sdk-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.client-sdk-modal.client-sdk-modal-fade-in {
    opacity: 1;
}

.client-sdk-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.client-sdk-modal-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1041;
}

.client-sdk-modal-loader {
    border: 3px solid transparent; /* Light grey */
    border-top: 3px solid white; /* Blue */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: crossmintSpin 1s linear infinite;
}

@keyframes crossmintSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

